@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

body {
  margin: 0px;
  font-family: "Verdana", "Geneva", sans-serif;
  font-size: xx-large;
}
.wrapper {
  width: 100%;
  height: 100px;
}

.error-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 20px;
  z-index: 1;
}

.filter {
  display: flex;
  justify-self: center;
}

.button-wrapper {
  margin: 10px;
  display: flex;
  justify-content: right;
}

.footer {
  width: 100vw;
  height: 70px;
  background-color: #8b8e66;
  padding-top: 20px;
}

.error-message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
  border: solid red 1px;
}

.success {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.content {
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  min-height:100vh;
}

.table-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 100vw;
}